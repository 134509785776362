import api from '@/api'
import tableHeaders from '@/utils/tableHeaders'

const state = {
	projectId: '',
	testId: '',
	machineList: [],
	beforeJournal: [],
	journalForm: {},
	journalTableForm: {},
	journalFormKeys: {
		0: [],
		1: []
	},
	analysisResultList: [],
	isSubCategory: false,
	enteredResult: {}
}

const getters = {
	beforeJournal: state => {
		return state.beforeJournal.map(item => {
			item.text = `${item.testName.name}(${item.projectCode})`
			item.value = item.testId
			return item
		})
	},
	journalTableForm: state => state.journalTableForm,
	journalForm: state => state.journalForm,
	journalFormKeys: state => state.journalFormKeys,
	getTableFormData: (state) => (formKey) => {
		return state.journalTableForm[formKey]
	},
	analysisResultList: state => state.analysisResultList,
	machineList: state => {
		return state.machineList.map(item => {
			item.text = item.name;
			item.value = item.data;
			return item;
		})
	},
}

const mutations = {
	setProjectIds: (state, data) => {
		state.projectId = data.projectId;
		state.testId = data.testId;
	},	
	setMachine: (state, data) => {
		state.machineList = [...data]
	},
	setBeforeJournal: (state, data) => {
		state.beforeJournal = [...data]
	},
	setFormData: (state, data) => {
		const {key, value} = data;
		let result = {...state.journalForm}
		if(key === 'machine') {
			state.journalForm[key].data = value;
			state.journalTableForm[key].data = value;
			state.journalTableForm[key].list = value;
		}
	},
	_setJournalFormData: (state, data) => {
		state.journalForm = {};
		state.journalTableForm = {};
		const setType = (key) => {
			if(key && key.indexOf('Date') >= 0) {
				return 'date'
			}
			return 'text'
		}
		
		//form_data key 순서 기억
		state.journalFormKeys[0] = data.form_data[0] ? data.form_data[0].map(el => {return el.key}) : [];
		state.journalFormKeys[1] = data.form_data[1].map(el => {return el.key});
		let form_data = [...data.form_data[0], ...data.form_data[1]];
		let result = {}
		form_data.forEach(form => {
			result[form.key] = {
				headers: form.form.map(header => {
					header.type = setType(header.headerKey)
					header.key = header.headerKey
					header.label = header.text
					delete header.headerKey
					delete header.text
					return header;
				}),
				headersObj : {},
				list: [],
				key: form.key,
				name: form.name,
				optionalForm: form.optionalForm ? form.optionalForm.map(el => {
					el.value = ''
					return el
				}) : []
			};

			result[form.key].headers.forEach(el => {
				result[form.key].headersObj[el.key] = el
			})
		})

		//사용기기 리스트 데이터 세팅하기
		result['machine'] = {
			headers: [],
			list: [],
		};
		result['machine'].headers = tableHeaders.useDeviceTableHeader.map(header => {
			header.key = header.headerKey
			header.label = header.text
			return header
		})
		result['machine'].maker = data['machine']?.maker
		result['machine'].list = data['machine']?.data ? [...data['machine'].data] : []
		//사용 시약 리스트 데이터 세팅하기 - 사용 시약이 없는 데이터가 존재할 수도 있기 때문에 여부 확인 필요
		if(result['useReagent']) {
			result['useReagent'].list = data?.reagents ? [...data['reagents']] : []; 
		}

		//시험분석결과 데이터 세팅 (subCategory 가 있을 경우);
		let subCategoryData = [...state.analysisResultList];
		if(subCategoryData[0] && subCategoryData[0].subCategory) {
			let list = [];
			subCategoryData.forEach((item, index) => {
				const startIndex = list.length;
				const endIndex = startIndex + item.subCategory.length - 1;

				item.subCategory.forEach((sub,idx) => {
					sub.sampleName = idx === 0 ? sub.sampleName : '';

					let reform = {
						rowId: index, 
						sampleName: item.name, 
						testCategory: item.testCategory, 
						...sub, 
						// 
						categoryIndex : {
							start : startIndex, 
							end : endIndex,
							length : endIndex - startIndex + 1
						},
						//
						
					}					
					list.push(reform)
				})
				result['analysisResult'].orgLength = index;
			})
			result['analysisResult'].isSubCategory = true;
			result['analysisResult'].list = [...list]
		} else {
			result['analysisResult'].list = subCategoryData;
		}
		state.journalForm = {...data}
		state.journalForm.cvvType = '';	
		state.journalForm.cvvType2 = '';
		state.journalTableForm = {...result};
		state.journalForm.testMethod = data.method[0]
		state.journalForm.testMethodOptions = data.method.map(item => {
			return {
				text: item, 
				value: item
			}
		})
	},
	setAnalysisResult: (state, data) => {
		state.analysisResultList = [...data];
	},
	setEnteredResult: (state, {data, isBeforeSelect = false}) => {
		if(!data) {
			return 
		}
		const formKey = [...state.journalFormKeys[0], ...state.journalFormKeys[1]]
		//info가 있을 경우 데이터 변경
		//subCategory 대응도 info 가 있을 경우를 대비해서 진행
		if(data['machine']) {
			state.journalTableForm['machine'].list = [...data['machine'].data]
		}
		formKey.forEach(key => {
			if(data.optionalForm) {
				state.journalTableForm[key].optionalForm.forEach((optional,idx) => {
					state.journalTableForm[key].optionalForm[idx].value = data.optionalForm[key][optional.key]
				})
			}
			if(data[key]) {
				state.journalFormKeys[0].forEach((key,idx) => {
					state.journalTableForm[key].headers = [...data.form_data[0][idx]]
				})
				state.journalFormKeys[1].forEach((key,idx) => {
					state.journalTableForm[key].headers = [...data.form_data[1][idx]]
				})
				//2024.12.04 grace - 시험일지 불러오기일 경우에는 시험결과만 데이터 안불러오도록 조건 처리
				if(isBeforeSelect) {
					if(key !== 'analysisResult') {
						state.journalTableForm[key].list = [...data[key]]
					}
				} else {
					state.journalTableForm[key].list = [...data[key]]
				}
			}
			// //서브 카테고리가 있을 경우 list reform
			if(!isBeforeSelect && data[key][0] && data[key][0].subCategory) {
				let list = [];
				data[key].forEach((item, index) => {
					const startIndex = list.length;
					const endIndex = startIndex + item.subCategory.length - 1;
					item.subCategory.forEach((sub,idx) => {
						sub.sampleName = idx === 0 ? sub.sampleName : '';
						let reform = {
							rowId: index, 
							sampleName: item.name, 
							testCategory: item.testCategory, 
							...sub, 
							// 
							categoryIndex : {
								start : startIndex, 
								end : endIndex,
								length : endIndex - startIndex + 1
							},
							//
							
						}					
						list.push(reform)
					})
					state.journalTableForm[key].orgLength = index;
				})
				state.journalTableForm[key].isSubCategory = true;
				state.journalTableForm[key].list = [...list]
			}
		})
		state.journalForm = {...state.journalForm, ...data};
		state.journalForm.testMethod = data.method[0]
		state.journalForm.testMethodOptions = data.method.map(item => {
			return {
				text: item, 
				value: item
			}
		})
	}
}


const actions = {
	//setting data
	loadJournal: async ({commit, dispatch}, {projectId, testId, isBeforeSelect}) => {
		//2024.12.04 grace - 시험일지 불러오기일 경우에는 시험결과만 데이터 안불러오도록 조건 처리
		commit('setProjectIds', {projectId, testId})
		if(!isBeforeSelect) {
			await dispatch('loadAnalysisResult', {projectId, testId})
		}
		await dispatch('loadBeforeJournal', testId)
		await dispatch('loadMachine')
		await dispatch('loadJournalFormData', testId)
		await dispatch('loadEnteredTestResult', {projectId, testId, isBeforeSelect})
	},
	//시험일지 - 기기리스트 가져오기
	loadMachine: async({state, commit}) => {
		const res = await api.project.getMachine()
		commit('setMachine', res)
	},
	//시험일지 - 이전 시험일지 목록 가져오기
	loadBeforeJournal: async({state, commit}, testId) => {
		const res = await api.project.getTestJournal(testId)
		commit('setBeforeJournal', res)
	},
	//시험일지 - 입력된 시험결과 가져오기
	loadEnteredTestResult: async ({state, commit}, {projectId, testId, isBeforeSelect}) => {
		const res = await api.project.getResult(projectId, testId)
		commit('setEnteredResult', {data: res.result, isBeforeSelect: isBeforeSelect})
	},
	//시험일지 - form data 가져오기
	loadJournalFormData: async ({state, commit}, testId) => {
		const res = await api.project.getJournalForm(testId)
		commit('_setJournalFormData', res)
	},
	//시험일지 - 입력해야하는 시료 목록 가져오기
	loadAnalysisResult: async({state, commit}, {projectId, testId}) => {
		const res = await api.project.getSampleResultList(projectId, testId)
		commit('setAnalysisResult', res)
	},
	//시험일지 - 시험결과 저장하기
	editJournal: async({state, commit}, payload) => {
		const reformData = _setReformData(JSON.parse(JSON.stringify({journalForm : state.journalForm, journalTableForm : state.journalTableForm, journalFormKeys: state.journalFormKeys, paramsForm: payload.form})))
		const res = await api.project.updateJournalResult(payload.projectId, state.testId, reformData, payload.autoSave);
		return res;
	}
}

function _setReformData(state) {
	//임시로 만들어준 데이터 제거
	let reform = {...state.journalTableForm};
	//subCategory 가 있을 경우 list data reform 필요
	const reformDataKeys = Object.keys(reform);
	let optionalForm = {};
	if(state.paramsForm) {
		state.journalForm = {...state.journalForm, ...state.paramsForm}
	}
	reformDataKeys.forEach(key => {
		state.journalFormKeys[0].forEach((initKey, idx) => {
			if(initKey === key) {
				state.journalForm.form_data[0][idx] = [...reform[key].headers]
			}
		})
		state.journalFormKeys[1].forEach((initKey, idx) => {
			if(initKey === key) {
				state.journalForm.form_data[1][idx] = [...reform[key].headers]
			}
		})

		//optional form reform
		if(reform[key].optionalForm) {
			reform[key].optionalForm.forEach((optional,idx) => {
				if(!optionalForm[key]) {
					optionalForm[key] = {}
				}
				
				optionalForm[key][optional.key] = reform[key].optionalForm[idx].value
			})
			state.journalForm.optionalForm = {...optionalForm}
		}

		const orgLength = reform[key].orgLength;
		if(orgLength) {
			const tempResult = {};
			reform[key].list.forEach(el => {
				if(typeof tempResult[el.categoryIndex.start] === 'undefined') {
					tempResult[el.categoryIndex.start] = {
						testCategory : state.journalForm.name,
						subCategory : []
					}
				}
				
				tempResult[el.categoryIndex.start].subCategory.push(el)
			})
			
			reform[key] = Object.keys(tempResult).map(key => {
				return tempResult[key]
			})
		} else {
			if(reform[key].list) {
				reform[key] = reform[key].list
			} else {
				// delete reform[key]
			}
		}
	})

	//machine 부분의 데이터 구조 변경
	reform['machine'] = {
		name: state.journalForm.machine?.name,
		data: reform['machine'],
		maker: state.journalForm.machine?.maker
	}
	const confirm = {...state.journalForm, ...reform}
	return {...state.journalForm, ...reform}
}

export default { namespaced: true, state, getters, mutations, actions }