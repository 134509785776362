import api from '@/api'
import format from '@/utils/format'
import status from '@/utils/status'

const state = {
	params : {
		keyword : '',
		page : 1,
		count : 30,
		filter: {}
	},
	list : [],
	count: 1,
	headers: [],
	filterList: {
		isApprove: {
			title: '승인 상태',
			options: []
		},
		testId: {
			title: '시험 항목',
			options: []
		},
		createDatetime: {
			title: '생성일',
			value: '',
			type: 'date'
		},
		endDatetime: {
			title: '발급 예정일',
			value: '',
			type: 'date'
		},
	},
	chartsets: {
		labels: [''],
		datasets: [
			{
				label: '진행중',
				data: [3],
				backgroundColor: '#FF922D',
				borderRadius: {
					topLeft: 5,
					bottomLeft: 5,
				},
				borderSkipped: false
			},
			{
				label: '분석완료',
				data: [10],
				backgroundColor: '#6C60CD'
			}
		]
	},
	filterCategoryList: []
}

const getters = {
	headers: state => {
		return state.headers.map(header => {
			return {
				key: header.headerKey,
				label: header.text,
				isSort: header.isSort,
				sort: header.sort,
				link: header.link,
				formatter: (value, key, item) => {
					if(!value) return '-'

					if(key.indexOf('Date') >= 0) {
						value = format.date({date: value, onlyDate: true})
					}
					else if(key === 'isApprove') {
						value = status.approval.filter(el => el.value === value)[0]
					}
					else if(key == 'status') {
						value = { text: status.text[value], color: status.color[value]}
					}
					return value;
				}
			}
		})
	},
	list: state => {
		return state.list.map(item => {
			item.isNew = item.createDatetime >= format.date({date: new Date(), onlyDate: true}) ? true : false
			return item
		})
	},
	count: state => state.count,
	params: state => state.params,
	filterList: state => {
		state.filterList.testId.options = state.filterCategoryList.map(el => {
			el.text = el.name;
			el.value = el.id;
			return el;
		});
		return state.filterList
	},
	chartsets: state => state.chartsets,
	filterCategoryList: state => state.filterCategoryList
}


const mutations = {
	setList: (state, data) => {
		state.list = [...data.table.list]
		state.count = data.table.count
		state.headers = [...data.table.header]
		state.chartsets = {...state.chartsets}
		state.chartsets.datasets[0].data = [data.summary['WAIT']];
		state.chartsets.datasets[1].data = [data.summary['COMPLETE']];
	},
	setParams: (state, data) => {
		state.params = {...state.params, ...data}
	},
	resetParams: (state) => {
		state.params = {
			keyword : '',
			page : 1,
			count : 30,
			filter: {}
		}
	},
	setFilterList: (state, data) => {
		state.filterList = {...state.filterList}
		state.filterList.isApprove.options = [...status.approval]
	},
	setFilterCategoryList: (state, data) => {
		state.filterCategoryList = [...data]
	}
}

// async 됨 
const actions = {
	loadAnalysisList: async ({commit, state}, params) => {
		const res = await api.analysis.getList(params ? params : state.params);
		commit('setList', res)
		commit('setFilterList')
	},
	loadFilterCategoryList: async ({state, commit}) => {
		const res = await api.analysis.getFilterCategoryList();
		commit('setFilterCategoryList', res)
	}
}


export default { namespaced: true, state, getters, mutations, actions }